import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, FirstP } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>Brushless motors are becoming more and more popular in many different devices and hobby projects, from drones all the way to electric skateboards and bikes. Compared to DC motors they have a much better performance, but this comes at the cost of more complex motor controls. In this project I will design my own circuit board and software for a BLDC controller.</FirstP>
         
        <p>There are three main reasons I am looking into this:</p>

        <ol>
            <li>When working on my drone I was quite annoyed by my (admittedly cheap) ESC's. There was limited to no documentation and no way of knowing what it is doing exactly.
            </li>
            <li>I want to do more with brushless motors in the future. At one point I would for example like to build my own CNC machine, and for this I would love to use
                BLDC's instead of stepper motors to do true closed loop control.
            </li>
            <li>
                I have some experience with BLDC control from my day job, so I have a head start. But nevertheless, its an area where I would like to learn a lot more.
            </li>
        </ol>

        <p>
            In this log I will give a quick introduction to the workings of a BLDC, as well as the different methods for controlling it.
        </p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Commutation" />
        <Caption>Six step commutation for an inrunner motor. The light blue winding is inactive, and red and blue are the north and south poles respectively.</Caption>

        <h3>Working Principle</h3>

        <p>
            A BLDC has a permanent magnet rotor, and a stator consisting of three phase windings. By sending a current through the windings a magnetic field is generated which will cause a movement
            in the rotor. The permanent magnets and thus the rotor can be inside of the windings for a more conventional motor layout, but also outside of the windings. This is more typical in drone 
            motors and for that reason these are called outrunners.
        </p>        

        <p>
            Looking at the picture above you can see how this works. Here the rotor is inside the stator and the orientation is depicted with the pointer. Each time the rotor moves, the stator will be 
            energized in such a way that the rotor is attracted to a future position (opposites attract), and repelled by a previous position. By sequentially switching which phases are conducting in this fashion, 
            the motor will keep rotating.
        </p>

        <h3>Trapezoidal commutation</h3>

        <p>
            The control scheme above is known as trapezoidal control. This is the simplest way to drive a BLDC motor requiring little computing power and only cheap hardware. 
            But as you can also imagine, the main drawback is that control is quite crude, resulting in a noticable torque ripple especially at lower speeds.
        </p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Six Step Commutation" />
        <Caption>The three phase currents during trapezoidal commutation such as shown in the previous figure.</Caption>

        <p>
            At this point I would also like to add that there are actually different types of motor constructions with a different back-emf shape. 
            In the strictest sense of the word a BLDC motor has a trapezoidally shaped back-emf
            such that the motor is specifically designed to be as efficient as possible with this simple commutation method.            
        </p>

        <p>
            There are also motors in which the shape of the back-emf is sinusoidal. These are sometimes known as BLAC motors or as permanent magnet synchronous machines (PMSM).
            Driving these motors with trapezoidal commutation will be less efficient. Instead, it is better to drive them with a sinusoidal commutation pattern which will be described below.
        </p>

        <p>
            But how about the other way around? Literature seems to suggest that driving a trapezoidal motor with a sinusoidal commutation has almost no impact on the efficiency.
            However, could it be better in other areas? I could not yet find clear answers regarding the impact on torque ripple, noise or other characteristics. 
            It does not help that many motors that are sold as BLDC, have in fact a sinusoidal back-emf design, adding to the confusion. Hopefully I can get better answers on this after my own testing.
        </p>

        <h3>Sinusoidal commutation</h3>

        <p>Sinusoidal commutation is basically following the exact same principle, but by smoothing out the steps you will end up with less torque ripple and more accurate control of position.
        </p>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="Sinusoidal Commutation" />
        <Caption>The three phase currents during sinusoidal commutation overlayed on the previous figure.</Caption>

        <p>The drawback of sinusoidal control occurs at higher speeds. As you can imagine to track this sinusoidal profile you need to control the current very accurately to follow
            this waveform. At low speeds this is not an issue, but at higher speeds it becomes more and more difficult to do so since the setpoint to the current control loop is also time 
            varying. At one point the bandwidth of the control loop is no longer enough.
        </p>

        <h3>Field-oriented control</h3>

        <p>
            This is where field-oriented control (FOC) comes in. By some mathematical magic known as the Clarke and Park transforms the motor phase currents will be transformed into two orthogonal components Id and Iq.
            Iq is the component resulting in a motor torque, and Id controls the magnetic flux. For a steady state motor rotation these torques will be constant. Therefore, we
            can now control to a fixed setpoint value instead of a time varying sinusoidal waveform. Because of this FOC will not have the high speed limitations, and will offer the best of both worlds of trapezoidal and sinusoidal controls.
        </p>

        <p>In the next post I will go into more detail into the design choices I will need to make for the controller, and what requirements I have set for the hardware itself.</p>

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "commutation.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "commutation2.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img3: file(relativePath: { eq: "commutation3.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
// Three different commutation strategies are compared, and
// their characteristics are investigated in this paper.The
// trapezoidal commutation generates torque ripples at low
// speeds and is relatively efficient in the high speed range.
// The sinusoidal commutation provides smooth operation at
// low speeds, but it is inefficient in high speed range.The
// FOC gives the best of both trapezoidal and sinusoidal
// commutations – smooth operation at low speed and
// efficient running at high speed.The torque ripple can be
// significantly reduced by matching the motor type with the
// commutation strategy.The adequate combinations for
// producing minimum toque ripple are the BLDC motor
// with the six - step commutation and the PMSM with the
// sinusoidal commutation.The FOC can apply to both types
// of motor to get the best drive performances, but it may
// require extra code development and hardware
// components.The motor current waveforms are sinusoidal
// with both the sinusoidal and the FOC commutation cases.